<template>
  <div id="pageNotFound">
    <!-- <div id="sliderParentContainer" class="slider-parent-container frosted-glass mr-4 ml-4">
      <div id="page-not-found-slider-container" class='container low-index-ui'>
        <div class="row pl-4 pr-4">
            <div class="col-12 col-lg-12 col-sm-12 m-0 p-1">
              <div class="standard-text title p-2 text-left">Inputs</div>
            </div> -->
            <!-- <div class="col-2 align-self-center d-flex justify-content-end">
              <div class="exit-button" @click="inputPanelButtons[0].active = false" title="close panel">
                <exitButton />
              </div>
            </div> -->
          <!-- </div> -->
        <!-- <div class="row h-100 mb-4">
          <div class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto d-flex flex-column justify-content-around">
            <div v-for="input in inputObject" :key="input.label+'-key'">
              <div class="standard-text label">{{input.label}}</div>
              <vue-slider :title="'adjust ' + input.label" v-model="input.value" :data="input.data" :ref="input.ref"  @drag-end="setInputValues" @error="sliderError" :stop-propagation="true" v-bind="sliderOptions" :marks="input.marks" :clickable="false" :absorb="true" :lazy="true">
              </vue-slider>
            </div>
          </div>
        </div> -->
      <!-- </div>
    </div> -->
    <div id="pageNotFoundContent" ref="pageNotFoundContent">
      <!-- <canvas id="pageNotFoundCanvas" ref="pageNotFoundCanvas"></canvas> -->
    </div>
    <div id="messageContainer" class="low-index" >
      <h4>uh oh!</h4>
      <h5>We are unable to find the page you requested, link to return <router-link to="/">Home</router-link></h5>
    </div>
  </div>
</template>

<script>

// vue slider import
//import VueSlider from 'vue-slider-component'
// slider template styling
//import 'vue-slider-component/theme/default.css'

// import helper functions
// import {
//   ConstructScene,
//   Lights,
//   filterModelData,
//   disposeScene
// } from '@/assets/js/helper.js'

// import {
//   onWindowResize,
//   setupModelScene,
//   getContextModel,
//   loadModel
// } from '@/assets/js/sceneHelpers.js'

// const THREE = require('three')
// const OrbitControls = require('three-orbit-controls')(THREE)
// const loader = new THREE.ObjectLoader()

// let scene = []
// let renderer = null
// let controls = null
// let camera = null
// let sceneSettings = []
// let modelData = []
// let inputHeaders = []
// let contextObject = []
// let contextModelData = []

export default {
  name: 'pageNotFound',
  components: {
    // VueSlider
  },
  data () {
    return {
      inputObject: [],
      inputHeaders: [],
      currentModelID: '0',
      modelData: [],
      modelIDPrefix: 'model-',
      sceneInit: false,
      sliderOptions: {
        'dotSize': 14,
        // "marks": true,
        'lazy': true,
        'process': false,
        'tooltip': 'none',
        'railStyle': {
          'background-color': 'rgba(253, 203, 204, 0.41)'
        }
      }
    }
  },
  created () {
    // this.$store.dispatch('getPageNotFoundData').then((response) => {
    //   this.inputObject = response.inputObject
    //   inputHeaders = response.inputHeaders
    //   modelData = response.modelData
    //   contextObject = response.contextObject
    //   contextModelData = response.contextModelData

    //   this.init()
    // })
  },
  unmounted () {
    // window.removeEventListener('resize', this.render, false)

    // disposeScene(scene, renderer)
  },
  computed: {
    // canvas () {
    //   return this.$refs['pageNotFoundCanvas']
    // },
    // canvasContainer () {
    //   return this.$refs['pageNotFoundContent']
    // }
  },
  methods: {
    init () {
      // this.$store.commit('setLoadingFlag', true)
      // this.canvas.classList.add('inactive')

      // const createScene = new ConstructScene(
      //   THREE.Scene,
      //   new THREE.Color('rgb(24,24,24)'),
      //   new THREE.Fog(0x1a2050, 10000, 10000)
      // )

      // scene = createScene.scene

      // // CAMERA
      // createScene.constructCamera(THREE.PerspectiveCamera, 0)

      // camera = createScene.camera

      // // RENDERER
      // renderer = new THREE.WebGLRenderer({
      //   canvas: this.canvas,
      //   antialias: true
      // })

      // // renderer.setClearColor(0x010101, 0);
      // renderer.setPixelRatio(window.devicePixelRatio)
      // renderer.setSize(window.innerWidth, this.canvasContainer.offsetHeight)

      // // CONTROLS
      // createScene.constructControls(OrbitControls, renderer.domElement)
      // controls = createScene.controls
      // controls.enablePan = false

      // // SETUP LIGHTS
      // const lights = new Lights()

      // lights.createDirectionalLight()

      // scene.add(lights.ambient)
      // scene.add(lights.directionalLight)
      // scene.add(lights.directionalLight2)

      // this.canvasContainer.appendChild(renderer.domElement)

      // this.createModelScene(this.currentModelID)
    },
    updateSceneListeneres () {
      // window.addEventListener('resize', this.render, false)

      // this.$store.commit('setLoadingFlag', false)

      // this.canvas.classList.remove('inactive')

      // controls.update()
    },
    removeModelByName (ID) {
      // let selectedObject = scene.getObjectByName(ID)

      // selectedObject.children.forEach(function (child, i) {
      //   child.geometry.dispose()
      //   child.material.dispose()
      //   child = undefined
      // })

      // scene.remove(selectedObject)

      // selectedObject = undefined

      // renderer.dispose()
      // renderer.renderLists.dispose()
    },
    async createModelScene (modelID) {
      // let contextObj = await getContextModel(contextObject)

      // let obj = await this.getModelByID(modelID)

      // // setupModelScene(model, modelID, contextModel).then((e) => {
      // const [model, contextModel, cameraRadius, modelSettings] = setupModelScene(obj, modelID, contextObj)

      // sceneSettings = modelSettings

      // controls.target.set(0, modelSettings.y, 0)
      // controls.update()
      // controls.addEventListener('change', this.render)

      // camera.position.set(cameraRadius, cameraRadius, -cameraRadius)
      // camera.aspect = (window.innerWidth / this.canvasContainer.offsetHeight)
      // camera.updateProjectionMatrix()

      // scene.add(contextModel)
      // scene.add(model)

      // this.setupContextModelData(contextModelData)
      // });
      // getContextModel(contextObject).then((contextModel) => {

      //   const model = this.getModelByID(modelID);

      //   // this.$store.dispatch('getModelByID', [modelID + '_option.json', 'projects/404']).then(response => {

      //   // })
      // })
    },
    async getModelByID (modelID) {
      // const response = await this.$store.dispatch('getModelByID', [modelID + '_option.json', 'projects/404'])

      // return loadModel(response.data.model, modelID)
    },
    createContextCopies (loadedContextModels) {
      // const localScene = new THREE.Scene()

      // contextModelData.forEach((modelObject) => {
      //   const { x, y, z, type } = modelObject

      //   let geo = new THREE.Object3D()

      //   const key = type + '.json'

      //   geo.copy(loadedContextModels[key])

      //   geo.position.set(parseFloat(x), parseFloat(z), parseFloat(y))
      //   geo.rotation.y = (Math.PI / 2) * (Math.random() * (Math.PI * 2))

      //   localScene.add(geo)
      // })

      // localScene.position.set(sceneSettings.x, sceneSettings.y, sceneSettings.z)

      // scene.add(localScene)
    },
    setupContextModelData () {
      // const modelNames = [...new Set(contextModelData.map(item => item.type))]

      // const loadedContextModels = {}

      // return new Promise((resolve, reject) => {
      //   this.$store.dispatch('getContextModelData', modelNames).then((response) => {
      //     response.forEach((m) => {
      //       loader.parse(m, (model) => {
      //         model.name = m.metadata.ID

      //         loadedContextModels[model.name] = model
      //       })
      //     })

      //     // this.createContextCopies(loadedContextModels)
      //     this.updateSceneListeneres()
      //   })
      // })
    },
    render () {
      // onWindowResize(camera, renderer, this.canvasContainer.clientHeight, true)

      // renderer.render(scene, camera)
    },
    async setInputValues () {
      // const values = {}

      // Object.keys(this.inputObject).forEach((key) => {
      //   const element = this.inputObject[key]
      //   values[element['dataName']] = element['value']
      // })

      // const selectedModel = filterModelData(modelData, inputHeaders, values)[0]['iteration']

      // if (this.currentModelID !== selectedModel) {
      //   this.removeModelByName(this.modelIDPrefix + this.currentModelID)

      //   this.currentModelID = selectedModel

      //   const model = await this.getModelByID(this.currentModelID)

      //   model.position.set(sceneSettings.x, sceneSettings.y, sceneSettings.z)

      //   scene.add(model)

      //   this.render()
      // }
    },
    sliderError (err, message) {
      console.warn(err, message)
    }
  }
}
</script>

<style lang="scss">
#messageContainer{
  position: absolute;
  left: 33vw;
  bottom: 50px;
}
#pageNotFound{
  // height: calc(100vh - #{$navbar-height});
  // padding: 0 4rem;
  // top: $navbar-height;
  position: relative;

  overflow: hidden;
}
#sliderParentContainer{
  position: absolute;

  // top: calc(#{$navbar-height});
  width: 500px;

  // max-height: calc(97vh - #{$navbar-height});

  overflow-x: hidden;
  overflow-y: auto;

  pointer-events: all;

  z-index: 2;
}

#pageNotFoundContent {
  width: 100vw;
  // height: calc(100vh  - #{$navbar-height});

  canvas{
    &.inactive{
      display: none;
    }
  }
}

</style>
